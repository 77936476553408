<template>
  <div class="promise-page-en">
    <div class="promise-sec1">
      <h1>We promise<br>Committed to delivering high-value</h1>
      <p>When you join Monglish, you will be able to control the speed of your academic achievement and progress. Once
        you enroll in our courses, wide and flexible prospects for development would open up before you. You would able
        to control the rate of your studies and the extent of your progress, within suitable timeframe and conditions.
        This high level of flexibility in taking lessons makes educational attainment more efficient, with a much higher
        return, as evident by the long-term results realized.</p>
      <div class="imagee-containerr">
        <img src='@/assets/images/promise1.png' alt="promise1">
        <img src='@/assets/images/promise2.png' alt="promise2">
      </div>
    </div>

    <div class="content-promise">
      <div class="image-promise">
        <img src="@/assets/images/promise-sec2.png" alt="promise-sec2">
      </div>
      <div class="text-promise">
        <h2>A Cheerful Experience</h2>
        <p>With Monglish, learners will enjoy studying and enriching their knowledge with new information that is easily
          retained in memory, as a result of the variety of exciting methods used in teaching. At Monglish, we present
          novelty information across different topics, in a manner that attracts the learner's attention at a faster
          pace. If you are ready to teach your child English, communicate in the language, and excel, you have reached
          the right school! We offer a variety of courses that suit learners of all levels of English proficiency, as
          well as of different age groups. </p>
      </div>
    </div>

    <div class="vision-sec3">
    <h1>Confidence Building</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262964"
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
    </div>

    <div class="promise-sec3-space">
      <div class="promise-sec3">
        <div class="promise-sec3-inner">
          <div class="text-content-promise">
            <h1>Continuous Improvement</h1>
            <p>Regardless of your level of English, or the learning method that you prefer, we can guide you through our
              effective English language courses and clubs, offered on our unique online platform. Our courses are
              designed specifically to meet the needs of learners at all levels, thus they result in upgrading their
              English language skills quickly and effectively, in addition to making the learning experience more fun
              and entertaining, compared to traditional methods. </p>
          </div>
        </div>
      </div>
    </div>

    <div class="slider-3d">
      <h1>Want To Hear<br>It From Those Who Experienced It?</h1>
      <div class="carousel-container">
        <button class="slider-nav left" @click="moveLeft">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </button>

        <transition-group name="slide" tag="div" class="carousel-track">
          <div v-for="(item, index) in generateItems()" :key="index" :class="['item', 'level' + item.level]">
            <img :src="item.src" :alt="'Image ' + index" @click="openPopup(item.videoSrc)" />
          </div>
        </transition-group>

        <button class="slider-nav right" @click="moveRight">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>

      <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
        <div class="popup-content" @click.stop>
          <button class="close-button" @click="closePopup">&times;</button>
          <iframe
            v-if="currentVideoSrc"
            :src="currentVideoSrc"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="popup-video"
          ></iframe>
        </div>
      </div>
    </div>

    <div class="kids-sec8">
    <div class="kids-sec8-row">
      <h4>ARE YOU SEEKING TO IMMERSE YOURSELF IN THE LANGUAGE IMMEDIATELY? <br><span>WELCOME!</span></h4>
      <p>The positive feedback from our students clearly reflects the profound impact of our courses/ dedicated work on their career paths. They consistently affirm how these courses contribute to enhancing their performance at work and strengthening their practical skills. This underscores the significant value and immense benefit provided by our academy.</p>
    </div>

    <div class="adult-swiper">
      <div ref="swiperContainer" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(review, index) in reviews" :key="index" class="swiper-slide">
            <p>
              <span class="review-stars">{{ '★'.repeat(review.stars) }}</span>
            </p>
            <p>{{ review.review }}</p>
            <p class="review-by">{{ review.by }}</p>
          </div>
        </div>
      </div>
    </div>
    </div>

  <!--Section Four Content-->
  <div class="kids-sec4">
    <h3>Internationally Accredited</h3>
    <h1>High Quality at Every Step</h1>
<p>Monglish International Academy is internationally accredited by prominent institutions such as the International Organization for Training Quality (IOTQ) and is ISO 9001:2015 certified by the American Accreditation Association (UAF). We are also members of the American Accreditation Association (AAA), the Egyptian Society for Quality (ESQ), and the Arab Organization for Quality (AROQA) under the umbrella of Talal Abu-Ghazaleh (TAG-ORG), reflecting our commitment to global standards in higher education.</p>    <div class="kids-sec4-imgs">
        <img src="@/assets/images/Rectangle-6122.png"/>
        <img src="@/assets/images/Rectangle-6116.png"/>
        <img src="@/assets/images/Rectangle-6115.png"/>
        <img src="@/assets/images/Rectangle-6114.png"/>
        <img src="@/assets/images/Rectangle-6117.png"/>
        <img src="@/assets/images/Rectangle-6123.png"/>
        <img src="@/assets/images/Rectangle-6118.png"/>
        <img src="@/assets/images/Rectangle-29917.png"/>
    </div>    
  </div>

    <div class="vision-sec5">
    <h1>Totally Immersive</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262990"
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'KidsCourses',
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      active: 0,
      items: [
        { src: require('@/assets/images/video/20.png') , videoSrc: 'https://www.youtube.com/embed/u4pJkaKFDlo?autoplay=1'},
        { src: require('@/assets/images/video/21.png') , videoSrc: 'https://www.youtube.com/embed/QkotruhuVPM?autoplay=1'},
        { src: require('@/assets/images/video/22.png') , videoSrc: 'https://www.youtube.com/embed/q473RldRfrs?autoplay=1'},
        { src: require('@/assets/images/video/23.png') , videoSrc: 'https://www.youtube.com/embed/fcdBZnoxqdg?autoplay=1'},
      ],
      autoSlideInterval: null,
      activeDescription: null,
      totalSections: 3,
      currentIndex: 0,
      reviews: [
        {
          stars: 5,
          review:
            'جزاك الله خير انا حضرت المحاضرة كامله معاها ماشاء الله أسلوبه متميز يتسم بالهدوء أثناء الشرح',
          by: 'عبد الله عوده',
        },
        {
          stars: 5,
          review:
            'الله يعطيكم العافية بصراحه تمنيت اني دخلت عبد الرحمن من زمان لاني لاحظت تطور كبير و هذا بفضل الله وتوفيقة ثم بفضل جهدكم معانا واهتمامكم بأدق ادق التفاصيل و متابعة الواجابات و حرصكم بتذكيرنا بمواعيد الاندية كانه ابن لكم',
          by: 'ام عبد الرحمن',
        },
        {
          stars: 5,
          review:
            'لا بجد ما شاء الله ربنا يبارك فيها من أجمل واحسن المدرسين اللي في الأكاديمية و غير الضمير الي عندها ربنا يرضيها يارب في حياتها',
          by: 'والدة إياد',
        },
        {
          stars: 5,
          review:
            'السلام عليكم ورحمة الله وبركاته نادي القراءة حقيقي اللي مسؤوله عنه مره ممتازة ما شاء الله و بترد في اسرع وقت وتقوم بتحديد الموعد و ملتزمة جدا جزاها الله خيرا',
          by: 'فهد محمد',
        },
      ], swiper: null, 
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperContainer = this.$refs.swiperContainer;
      if (swiperContainer) {
        this.swiper = new Swiper(swiperContainer, {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        this.startAutoNavigate();
      }
    });

    this.autoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoNavigate() {
      setInterval(() => {
        if (this.swiper) {
          this.swiper.slideNext(); 
        }
      }, 3000); 
    },
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    generateItems() {
    const items = [];
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      items.push({
        src: this.items[this.active].src,
        videoSrc: this.items[this.active].videoSrc, 
        level: 0
      });
    } else {
      for (let i = this.active - 2; i <= this.active + 2; i++) {
        let index = i;
        if (i < 0) index = this.items.length + i;
        if (i >= this.items.length) index = i % this.items.length;

        items.push({
          src: this.items[index].src,
          videoSrc: this.items[index].videoSrc, 
          level: this.active - i
        });
      }
    }

    return items;
  },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    autoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    }
  }
}
</script>

<style scoped>
.promise-page-en {
  direction: ltr;
}
.promise-sec1 {
  color: white;
  text-align: center;
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  margin-top: -85px;
  padding-top: 140px;
  padding-left: 5%;
  padding-right: 5%;
}
.promise-sec1 h1 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  margin-bottom: 1rem;
}
.promise-sec1 p {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  margin: 0 auto;
  max-width: 80%;
}
.imagee-containerr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; 
  padding: 1em 0;
}
@media (max-width: 768px) {
  .promise-sec1 {
    height: auto;
    padding-top: 100px;
  }
  .promise-sec1 h1 {
    font-size: 2rem;
  }
  .promise-sec1 p {
    font-size: 1.125rem;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }
  .imagee-containerr {
    flex-direction: column; 
  }
}
@media (max-width: 480px) {
  .promise-sec1 h1 {
    font-size: 1.75rem;
  }
  .promise-sec1 p {
    font-size: 1rem;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }
}

.content-promise {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center; 
  padding: 40px;
  box-sizing: border-box;
  font-family: 'DIN Next LT Arabic', sans-serif;
}
.text-promise {
  flex: 1; 
  padding-right: 20px; 
}
.text-promise h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #165e84;
}
.text-promise p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}
.image-promise {
  flex: 1;
}
.image-promise img {
  width: 100%;
  height: auto;
  max-width: 600px; 
  border-radius: 8px; 
}
@media (max-width: 768px) {
  .content-promise{
    flex-direction: column;
    justify-content: center;
  }
  .text-promise{
    padding-right: 0; 
  }
  .text-promise h2{
    font-size: 26px;
    text-align: center;
  }
  .text-promise p{
    font-size: 20px;
    text-align: center;
  }
}


.vision-sec3 {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 1.8em;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
@media (max-width: 480px) {
  .vision-sec3 h1 {
    font-size: 1.5em;
  }
  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}

.kids-sec8 {
  padding: 1% 2% 0 2%;
  background-color: white;
  height: auto; 
  direction: ltr;
}
.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #165e84;
}
.kids-sec8-row h4 {
  font-size: 2.5vw;
}
.kids-sec8-row span {
  font-size: 3vw;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 60%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
  .kids-sec8-row h4 {
    width: 40%;
    margin: 0;
    padding-left: 1em;
  }
  .kids-sec8-row p {
    width: 60%;
    margin: 0;
    padding-right: 1em;
  }
}
.adult-swiper {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 2em;
  background-color: #fff;
  margin-top: 1em;
}
.swiper-container {
  width: 100%;
  background-image: url('@/assets/images/accre_bg.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 15px; 
  overflow: hidden;
}
.swiper-slide {
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px; 
}
.swiper-slide p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  padding: 1em;
}
.review-stars {
  color: #ff9800;
  font-size: 2.5rem;
}
.review-by {
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.5rem; 
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #ff9800;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.promise-sec3-space{
  padding: 2% 5% 2% 5%; 
  background-color: #fff;
  direction: ltr;
}
.promise-sec3 {
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: url('@/assets/images/promise-sec3.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row; 
  transform: scaleX(-1);
}
.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-left: 5%;
  transform: scaleX(-1);
}
.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: left;
}
.text-content-promise h1 {
  font-size: 3em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.text-content-promise p {
  font-size: 1.3em;
  max-width: 65%;
  line-height: 1.6;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
}
@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column; 
    height: auto; 
  }
  .text-content-promise p {
    max-width: 100%; 
  }
}

.vision-sec5 {
  background-color: #fff;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec5 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}

.slider-3d {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  padding: 3% 1%;
  direction: ltr;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; 
  overflow: hidden;
  perspective: 1000px; 
}

.carousel-track {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  transform-style: preserve-3d; 
  transition: transform 0.8s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-enter-active, .slide-leave-active {
  animation: slideIn 0.8s ease forwards;
}

.slide-leave-to {
  animation: slideOut 0.8s ease forwards;
}

.slider-3d h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  text-align: center;
  padding: 0 0 2% 0;
}

.slider-nav {
  color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease;
  border: none;
}

.slider-nav:hover {
  color: #FFF;
  transform: scale(1.1);
}

.slider-nav:active {
  color: #165e84;
  transform: scale(1);
}

.slider-nav.left {
  left: 5%;
}

.slider-nav.right {
  right: 5%;
}

.item {
  position: relative;
  text-align: center;
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-3d img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.level-2 {
  transform: translateX(-60px) rotateY(-10deg) scale(0.6);
  opacity: 0.5;
}

.level-1 {
  transform: translateX(-1px) rotateY(-5deg) scale(0.8);
  opacity: 0.7;
}

.level0 {
  transform: translateX(0) rotateY(0deg) scale(1);
  opacity: 1;
}

.level1 {
  transform: translateX(1px) rotateY(5deg) scale(0.8);
  opacity: 0.7;
}

.level2 {
  transform: translateX(60px) rotateY(10deg) scale(0.6);
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;  
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease; 
}

.close-button:hover {
  color: #d9534f; 
}
@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  .slider-3d img {
    width: 80%;
    height: auto;
  }
}
/* Section 5 style */
.kids-sec4{
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  direction: ltr;
  text-align: center;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2em 2em 8em 2em;
}
.kids-sec4 h3{
  font-size: 2.5rem;
  padding: 3% 0 0 0;
}
.kids-sec4 h1{
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.kids-sec4 p{
  font-size: 1.2rem;
  padding: 1% 10% 5% 10%;
}
.kids-sec4-imgs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}
.kids-sec4-imgs img{
    width: 9%;
}
@media (max-width: 1200px) {
  .kids-sec4 h3 {
    font-size: 2.2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.5rem;
  }
  .kids-sec4 p {
    font-size: 1.1rem;
    padding: 1% 15% 5% 15%;
  }
  .kids-sec4-imgs img {
    width: 12%;
  }
}
@media (max-width: 992px) {
  .kids-sec4 h3 {
    font-size: 2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.2rem;
  }
  .kids-sec4 p {
    font-size: 1rem;
    padding: 1% 10% 5% 10%;
  }
  .kids-sec4-imgs img {
    width: 15%;
  }
}
@media (max-width: 768px) {
  .kids-sec4 h3 {
    font-size: 1.8rem;
  }
  .kids-sec4 h1 {
    font-size: 2rem;
  }
  .kids-sec4 p {
    font-size: 0.9rem;
    padding: 1% 5% 5% 5%;
  }
  .kids-sec4-imgs img {
    width: 20%;
  }
}
@media (max-width: 576px) {
  .kids-sec4 h3 {
    font-size: 1.5rem;
  }
  .kids-sec4 h1 {
    font-size: 1.8rem;
  }
  .kids-sec4 p {
    font-size: 0.85rem;
    padding: 1% 3% 5% 3%;
  }
  .kids-sec4-imgs img {
    width: 25%;
  }
}
@media (max-width: 400px) {
  .kids-sec4 h3 {
    font-size: 1.2rem;
  }
  .kids-sec4 h1 {
    font-size: 1.5rem;
  }
  .kids-sec4 p {
    font-size: 0.75rem;
    padding: 1% 2% 5% 2%;
  }
  .kids-sec4-imgs img {
    width: 30%;
  }
}
</style>
