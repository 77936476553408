<template>
  <div class="faq-section-n">
    <h3>أسئلة شائعة</h3>
    <h2>وإجابات لكل استفساراتكم</h2>
    <div class="faq-items-n">
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
        <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
        <button @click="toggleAnswer(index + 3)" :class="{ active: activeIndex === index + 3 }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index + 3 }">
            <span v-if="activeIndex === index + 3">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index + 3" :class="['answer', { 'answer-active': activeIndex === index + 3 }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ViewThree',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'لماذا أكاديمية مونجلش الدولية ؟',
          answer: 'تعد أكاديمية مونجلش الدولية رائدة فى مجالها من خلال تدريس اللغة الإنجليزية بنظام منهج المعايشة الحصرى والمسجل رسميا بمكتبة الكونجرس الأمريكية. إن هذا المنهج يتم تدريسه ١٠٠ أونلاين ويشمل ١١ نادى تفاعلى مليء بالتطبيقات العملية مما يمكن الدارسين من جميع الأعمار من ممارسة اللغة بمتعة باللغة.'
        },
        {
          question: 'ما هي المؤهلات التي يتمتع بها المعلمون لتعليم اللغة الانجليزية فى أكاديمية مونجلش؟',
          answer: 'أعضاء فريق مونجلش يضم نخبة من المدرسين الأكفاء متعددى الجنسيات " المصرية والأمريكية والكندية والبريطانية والأسترالية"، خبراء في تعليم اللغة الانجليزية، حيث يشاركونك خبراتهم وعلمهم بكل شغف لضمان إتقان جميع المهارات. يميّزنا في مونجلش الابتكار، حيث نحرص على مواكبة كل حديث ونطبق حلول جديدة للمشاكل التي يواجهها الطلاب أثناء التعلم.'
        },
        {
          question: "ما هي نوادي منهج المعايشة؟",
          answer: "تنقسم نوادي المعايشة في مونجلش إلى نوادي أساسية، نوادي تفاعلية، ونوادي لتنمية المهارات السلوكية والتربوية، إيمانًا منا بأهمية التربية قبل التعليم. تأتي هذه الأندية ضمن رؤية مونجلش 2025 التي تركز على الجانب التربوي والنفسي في رحلة تعلم اللغات، وهو جانب لا يقل أهمية عن أي عنصر في المنظومة التعليمية الشاملة لدى أكاديمية مونجلش.\n\nالأندية الأساسية: نادي المحادثة، نادي المناظرة، نادي السينما، ونادي القراءة.\n\nالأندية التفاعلية: نادي الصوتيات، نادي الكتابة، نادي الرسم، ونادي المواهب.\n\nالأندية التربوية: ماما مونجي، النادي الاجتماعي، واللايف كوتش."
        },
        {
          question: "هل يمكنني استرداد المبلغ؟",
          answer: "يحق للمتدرب المطالبة باسترداد رسوم التدريب قبل بداية الكورس بـ٤٨ ساعة كحد أقصى، مع تحمل رسوم اختبار تحديد المستوى. للمزيد من المعلومات، يرجى الاطلاع على صفحة الشروط والأحكام الخاصة بالتسجيل."
        },
        {
          question: "كيف يسرع منهج المعايشة الطلاقة في اللغة ٤ أضعاف؟",
          answer: "عن طريق المعايشة وممارسة اللغة باستمرار ٦ أيام في الأسبوع من خلال الحصص الأسبوعية وأندية مونجلش التفاعلية المصممة خصيصًا لكل طفل. يتمتع الطفل بخبرات ٤ إلى ٦ معلمين في الشهر الواحد، مما يعزز من سرعة تعلم اللغة. نوفر بيئة أجنبية لممارسة اللغة من خلال معلمين معتمدين دوليًا في التعليم التعاوني. منهج المعايشة قائم على التفاعل والحماس وليس التلقين والحفظ، مما يوفر تجربة تعليمية ممتعة للأطفال وبدون ملل."
        },
        {
          question: "كيف يمكنني تتبع تقدم طفلي؟",
          answer: "من خلال قسم مخصص لمتابعة مستوى الطالب خلال الحصص الدراسية وإرسال تقرير أسبوعي حول حالة ومستوى الطفل التعليمي، مع إضافة أي ملاحظات من شأنها المساهمة في تقدم الطالب لغويًا."
        },
      ],
      faqItems_2: [
        {
          question: "كيف يمكنني الاشتراك وما هو أنسب سن للطفل لبدء الدورات التعليمية؟",
          answer: "يمكنك الاشتراك من خلال موقعنا الإلكتروني أو التواصل مع خدمة العملاء عبر الواتساب. دورات اللغة الإنجليزية لدينا مناسبة للأطفال من سن ٥ سنوات."
        },
        {
          question: "كم من الوقت يلزم لتعلم اللغة الإنجليزية؟",
          answer: "يحتاج الطالب من ٦ أشهر إلى ١٢ شهر للوصول إلى طلاقة في التحدث باللغة."
        },
        {
          question: "ما هي مدة الحصة الدراسية؟",
          answer: "ساعة ونصف هي مدة الحصة وتتم مرتين أسبوعيًا."
        },
        {
          question: "كم عدد الطلاب في الحصة الدراسية؟",
          answer: "يتراوح عدد الطلاب من ٥ إلى ١٢ طالب، حسب الفئة العمرية للدارسين."
        },
        {
          question: "هل الحصة مسجلة أم لايف؟",
          answer: "جميع الحصص تُقدّم لايف من خلال منصة زووم، ويمكن تسجيل الحصة من قبل الإدارة بإعلام مسبق في حالة غياب الطفل لأسباب قهرية."
        },
        {
          question: "هل يحصل الأطفال على شهادة في نهاية دراستهم؟",
          answer: "نعم، يحصل الأطفال على شهادة أونلاين، كما يمكن طلب إرسال نسخة مطبوعة ومعتمدة من الأكاديمية إلى العنوان الخاص بكم أينما كنتم."
        },
        {
          question: "ما هي وسائل الدفع المتاحة؟",
          answer: "وسائل الدفع المتاحة حاليًا هي أونلاين فقط من خلال موقعنا الرسمي أو رابط الدفع المباشر عبر Stripe باستخدام بطاقة الصراف الآلي."
        }
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    navigateToFAQ() {
      this.$router.push('/ar/faq');
    }
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>

