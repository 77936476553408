<template>
  <div class="monglish-reasons">
    <div class="monglish-reasons-sec1">
      <div class="reasons-text">
      <h1>Perfectly Online</h1>
      <p>monglish_frontend's online methods assist learners in gaining knowledge about their skill level while
        interacting during the learning process. The learner socializes with the tutor, colleagues and service staff,
        thus benefiting from such activities available at any time and from anywhere. It offers compatibility with all
        schedules, with no need to disrupt them, as course timings allow for flexible placement during morning or
        evening slots. There is also the factor of saving time on the physical visits to the language study center,
        because learning is completely online.</p>
    </div>

    <div class="reasons-card">
      <h3>Help and Develop Your Child!</h3>
      <p>To achieve their future aspirations.</p>
    </div>
    </div>
  </div>

  <div class="vision-sec3">
    <h1>E-learning At Its Best</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262933" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <HomeReasons />

  <HomeCounter />

  <!--Section Four Content-->
  <div class="kids-sec4">
    <h3>Internationally Accredited</h3>
    <h1>High Quality at Every Step</h1>
<p>Monglish International Academy is internationally accredited by prominent institutions such as the International Organization for Training Quality (IOTQ) and is ISO 9001:2015 certified by the American Accreditation Association (UAF). We are also members of the American Accreditation Association (AAA), the Egyptian Society for Quality (ESQ), and the Arab Organization for Quality (AROQA) under the umbrella of Talal Abu-Ghazaleh (TAG-ORG), reflecting our commitment to global standards in higher education.</p>    <div class="kids-sec4-imgs">
        <img src="@/assets/images/Rectangle-6122.png"/>
        <img src="@/assets/images/Rectangle-6116.png"/>
        <img src="@/assets/images/Rectangle-6115.png"/>
        <img src="@/assets/images/Rectangle-6114.png"/>
        <img src="@/assets/images/Rectangle-6117.png"/>
        <img src="@/assets/images/Rectangle-6123.png"/>
        <img src="@/assets/images/Rectangle-6118.png"/>
        <img src="@/assets/images/Rectangle-29917.png"/>
    </div>    
  </div>


  <div class="vision-sec3">
    <h1>Immersion</h1>
    <div class="vision-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/_NO8PSCuf_c?si=Zx92Nr0DAy8MmBUl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import HomeCounter from '../../components/en/HomeCounter.vue';
import HomeReasons from '../../components/en/HomeReasons.vue';

export default {
  name: 'HomePage',
  components: {
    HomeCounter,
    HomeReasons
  },
}
</script>

<style scoped>
.monglish-reasons {
  background-image: url('@/assets/images/reason_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 110vh;
  margin-top: -85px;
  padding: 12% 1% 1% 1%;
  font-family: 'DIN Next LT Arabic';
  box-sizing: border-box;
  direction: ltr;
  transform: scaleX(-1);
}
.monglish-reasons-sec1 {
  transform: scaleX(-1);
}
.reasons-text {
  color: white;
  text-align: left;
  padding: 1em;
  padding-right: 50%;
}
.reasons-text h1 {
  margin-top: 150px;
  font-size: 3em;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
}
.reasons-text p {
  font-size: 1.3em;
}
.reasons-card {
  background-color: white;
  margin-top: 2%;
  padding: 1em 3em; 
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-align: left;
  width: 25%; 
}
.reasons-card h3 {
  color: #535353;
  font-size: 1.8em;
}
.reasons-card p {
  color: #535353;
  font-size: 1.3em;
}
@media (max-width: 768px) {
  .monglish-reasons {
    height: auto;
  }
  .reasons-text{
    text-align: center;
    max-width: 100%;
    padding-right: 0;
    padding-top: 8%;
  }
  .reasons-text h1 {
    margin-top: 20px;
    font-size: 2em;
  }
  .reasons-text p{
    font-size: 1em;
    line-height: 1.5em;
  }
  .reasons-card{
    width: 100%;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
  }
  .reasons-card h3{
    font-size: 1.5em;
  }
  .reasons-card p{
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .reasons-text h1{
    font-size: 1.5em;
  }
  .reasons-text p{
    font-size: 0.9em;
  }
  .reasons-card h3{
    font-size: 1.3em;
  }
  .reasons-card p{
    font-size: 0.9em;
  }
}

.vision-sec3 {
  background-color: #fff;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 1.8em;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
@media (max-width: 480px) {
  .vision-sec3 h1 {
    font-size: 1.5em;
  }
  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}

/* Section 5 style */
.kids-sec4{
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  direction: ltr;
  text-align: center;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2em 2em 8em 2em;
}
.kids-sec4 h3{
  font-size: 2.5rem;
  padding: 3% 0 0 0;
}
.kids-sec4 h1{
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.kids-sec4 p{
  font-size: 1.2rem;
  padding: 1% 10% 5% 10%;
}
.kids-sec4-imgs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}
.kids-sec4-imgs img{
    width: 9%;
}
@media (max-width: 1200px) {
  .kids-sec4 h3 {
    font-size: 2.2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.5rem;
  }
  .kids-sec4 p {
    font-size: 1.1rem;
    padding: 1% 15% 5% 15%;
  }
  .kids-sec4-imgs img {
    width: 12%;
  }
}
@media (max-width: 992px) {
  .kids-sec4 h3 {
    font-size: 2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.2rem;
  }
  .kids-sec4 p {
    font-size: 1rem;
    padding: 1% 10% 5% 10%;
  }
  .kids-sec4-imgs img {
    width: 15%;
  }
}
@media (max-width: 768px) {
  .kids-sec4 h3 {
    font-size: 1.8rem;
  }
  .kids-sec4 h1 {
    font-size: 2rem;
  }
  .kids-sec4 p {
    font-size: 0.9rem;
    padding: 1% 5% 5% 5%;
  }
  .kids-sec4-imgs img {
    width: 20%;
  }
}
@media (max-width: 576px) {
  .kids-sec4 h3 {
    font-size: 1.5rem;
  }
  .kids-sec4 h1 {
    font-size: 1.8rem;
  }
  .kids-sec4 p {
    font-size: 0.85rem;
    padding: 1% 3% 5% 3%;
  }
  .kids-sec4-imgs img {
    width: 25%;
  }
}
@media (max-width: 400px) {
  .kids-sec4 h3 {
    font-size: 1.2rem;
  }
  .kids-sec4 h1 {
    font-size: 1.5rem;
  }
  .kids-sec4 p {
    font-size: 0.75rem;
    padding: 1% 2% 5% 2%;
  }
  .kids-sec4-imgs img {
    width: 30%;
  }
}

.vision-sec3 {
  background-color: #fff;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-weight: 500;
  margin: 0 0 20px 0;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 28px;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
</style>